// remove 'use client' after the experiment is done

'use client';

import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import React from 'react';
import classNames from 'classnames';
import { Locale } from '@fxtr/i18n';
import { useActiveExperiment } from '$util/abTest';
import { ActiveExperiments } from '$util/abTest/experiments';
import { Icon } from '@/components/atoms/Icon';
import styles from './index.module.scss';

export type PriceTagSizes = 'medium' | 'large';

export interface PriceTagProps extends ComponentPropsWithoutRef<'div'> {
  readonly size?: PriceTagSizes;
  readonly angle?: number;
  readonly locale?: Locale;
}

function PriceTag30OFF({ locale }: { locale: Locale }) {
  const tagInfo = locale === Locale.EN_GB ? 'Save up to' : 'Économisez jusqu’à';
  const subTagInfo = locale === Locale.EN_GB ? 'off main dealership' : 'sur les prix en concession';
  return (
    <div className={styles.priceTag30Off}>
      <span className={styles.priceTag30OffInfo}>
        <Icon id="general/check-circle-broken" width={20} height={20} legacy={false} />
        {tagInfo}
        <span>30%</span>
      </span>
      <span className={styles.priceTag30OffSubInfo}>{subTagInfo}</span>
    </div>
  );
}

function PriceTagSaveAverage({ locale }: { locale: Locale }) {
  const tagInfo = locale === Locale.EN_GB ? 'Save on average' : 'Économisez en moyenne';
  const priceInfo = locale === Locale.EN_GB ? '£44' : '34 €';
  return (
    <div className={styles.priceTagSaveAverage}>
      <Icon id="general/check-circle-broken" width={20} height={20} legacy={false} />
      <span>{tagInfo}</span>
      <span>{priceInfo}</span>
    </div>
  );
}

function PriceTagAvailableCND({ locale }: { locale: Locale }) {
  const tagInfo = locale === Locale.EN_GB ? 'Collection & Delivery' : 'Collecte & Restitution';
  const subTagInfo = locale === Locale.EN_GB ? 'available' : 'à domicile inclus';
  return (
    <div className={styles.priceTagAvailableCND}>
      <span>
        <Icon id="general/check-circle-broken" width={20} height={20} legacy={false} />
        {tagInfo}
      </span>
      <span>{subTagInfo}</span>
    </div>
  );
}

export function PriceTag({
  className,
  children,
  size = 'large',
  angle = -6,
  locale,
  ...props
}: PriceTagProps): ReactNode {
  const isServicePagePriceTagUKActiveB = useActiveExperiment(ActiveExperiments.ServicePagePriceTagUK, 'B');
  const isServicePagePriceTagFRActiveB = useActiveExperiment(ActiveExperiments.ServicePagePriceTagFR, 'B');

  const isServicePagePriceTagUKActiveC = useActiveExperiment(ActiveExperiments.ServicePagePriceTagUK, 'C');
  const isServicePagePriceTagFRActiveC = useActiveExperiment(ActiveExperiments.ServicePagePriceTagFR, 'C');

  const isServicePagePriceTagB = locale && (isServicePagePriceTagUKActiveB || isServicePagePriceTagFRActiveB);
  const isServicePagePriceTagC = locale && (isServicePagePriceTagUKActiveC || isServicePagePriceTagFRActiveC);

  const isProductPagePriceTagUKActiveB = useActiveExperiment(ActiveExperiments.ProductPageHeroTagUK, 'B');
  const isProductPagePriceTagFRActiveB = useActiveExperiment(ActiveExperiments.ProductPageHeroTagFR, 'B');

  const isProductPagePriceTagUKActiveC = useActiveExperiment(ActiveExperiments.ProductPageHeroTagUK, 'C');
  const isProductPagePriceTagFRActiveC = useActiveExperiment(ActiveExperiments.ProductPageHeroTagFR, 'C');

  const isProductPagePriceTagB = locale && (isProductPagePriceTagUKActiveB || isProductPagePriceTagFRActiveB);
  const isProductPagePriceTagC = locale && (isProductPagePriceTagUKActiveC || isProductPagePriceTagFRActiveC);

  if (!children && !isProductPagePriceTagB && !isProductPagePriceTagC) return null;

  const renderTag = () => {
    if (isServicePagePriceTagB) {
      return <PriceTag30OFF locale={locale} />;
    }
    if (isServicePagePriceTagC) {
      return <PriceTagSaveAverage locale={locale} />;
    }
    if (isProductPagePriceTagB) {
      return <PriceTagAvailableCND locale={locale} />;
    }
    if (isProductPagePriceTagC) {
      return <PriceTag30OFF locale={locale} />;
    }
    return children;
  };

  return (
    <em
      className={classNames(PriceTag.displayName, styles.component, styles[size], className)}
      {...props}
      // @ts-ignore Pass prop to styles
      style={{ '--rotate-angle': `${angle}deg` }}
    >
      {renderTag()}

      {/* uncomment after the experiment is done */}
      {/* {children} */}
    </em>
  );
}
PriceTag.displayName = 'PriceTag';
